// Set the custom theme colors.
$white: #ffffff;

$light: #e6f9ff;
$dark: #110032;
$primary: #1846a6;
$secondary: #94377c;
$info: #ef97b8;
$success: #15a460;
$warning: #e2cb15;
$danger: #f11d09;

// Define additional spacers.
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 5)
);

// Remove the caret on the dropdown toggle.
$caret-width: 0;

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  color: $primary !important;
}

@import '~bootstrap/scss/bootstrap';