.container-fade-in {
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: linear;
}

.container-fade-in-down {
    opacity: 1;
    animation-name: fadeInDown;
    animation-duration: 0.5s;
    animation-timing-function: linear;
}

.container-fade-out {
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-timing-function: linear;
}

.container-shake {
    animation-name: shake;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    display: inline-block;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}